import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__bg app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading className="header__sub" title="Een Samenwerking met Buro3o" />
      <h1 className="app__header-h1">Welkom bij CW De Boerderij</h1>
      <p className="text__menu" style={{ margin: '2rem 0' }}>Wij heten U van harte welkom op De Boerderij, op het prachtige leefgoed Coudewater. Geniet van onze overheerlijke gerechten en warme gastvrijheid, allemaal op een betoverende locatie!</p>
     <a href= "https://drive.google.com/file/d/1DWVgxvcVCHWqgpxNqgWjR4TFefGS4vJG/view?usp=sharing">
      <button type="button" className="custom__button button__header">Bekijk ons Menu</button>
    </a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;