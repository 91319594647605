import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__bg app__aboutus flex__center " id="about">
<div className=" app__about_bg">
    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Over Ons</h1>
        {/* <img src={images.spoon} alt="about_spoon" className="spoon__img" /> */}
        <p className="text__menu">Bij CW de Boerderij geloven we in de kracht van ieder individu en bieden we een veilige en ondersteunende omgeving waarin onze jonge medewerkers kunnen leren, groeien en hun potentieel kunnen ontdekken. Kom langs voor de warme sfeer, blijf voor de impact die je maakt met elke kop koffie. Welkom bij CW de Boerderij - waar goed eten en sociale verantwoordelijkheid hand in hand gaan.</p>
        <a href="#footer">
        <button type="button" className="custom__button">Maak een Reservering!</button>
        </a>
      </div>

      {/* <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Over Buro3o</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="text__menu">De missie van Buro3o is het zorgen voor ontwikkeling door  aanbod in behandeling, begeleiding en ondersteuning voor jonge mensen met ontwikkelstoornissen of psychische kwetsbaarheid, waardoor de ontwikkeling weer op gang komt en zij weer de regie over hun eigen leven kunnen krijgen of behouden of groeien in regulatie, zelfredzaamheid en zelfstandigheid.</p>
        <a href="https://www.buro3o.nl/">
        <button type="button" className="custom__button">Lees meer...</button>
        </a>
        <img src={images.knife} alt="about_knife" />

      </div> */}
    </div>
    </div>
    <div className="floating-image">
    <img src={images.knife} alt="about_knife" />
    </div>
  </div>
);

export default AboutUs;