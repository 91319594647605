import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';
import { SiTiktok, SiFacebook, SiInstagram } from "react-icons/si";


const SpecialMenu = () => (
  <div className="app__specialMenu app__bg flex__center section__padding" id="menu">
   <div className="app__menu_bg">
    {/* <div className="app__specialMenu-title">
      <SubHeading title="Menu that fits your palatte" />
      <h1 className="headtext__cormorant">CW Brews</h1>
      <p className="text__menu">Naast onze bierbrouwerij, die bekend staat om zijn hoogwaardige bieren, functioneert deze sfeervolle locatie ook als een centrum voor dagbesteding. Hier combineren we de passie voor brouwen met de zorg voor de gemeenschap, door mensen een zinvolle daginvulling te bieden.</p>

    </div> */}

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        {/* <p className="app__specialMenu-menu_heading">Populaire Bieren</p>
        <div className="app__specialMenu_menu_items">
          {data.populairbeer.map((populairbeer, index) => (
            <MenuItem key={populairbeer.title + index} title={populairbeer.title} price={populairbeer.price} tags={populairbeer.tags} />
          ))}
        </div> */}
         <div className="app__specialMenu-title">
      {/* <SubHeading title="Menu that fits your palatte" /> */}
      <h1 className="headtext__cormorant">CW Brews</h1>
      <p className="text__menu">Naast onze bierbrouwerij, die bekend staat om zijn hoogwaardige bieren, functioneert deze sfeervolle locatie ook als een centrum voor dagbesteding. Hier combineren we de passie voor brouwen met de zorg voor de gemeenschap, door mensen een zinvolle daginvulling te bieden.</p>
      <div className="app__icons_brews">
          <a href="https://www.facebook.com/profile.php?id=61555195655912" target="_blank" rel="noopener noreferrer">
          <SiFacebook />
          </a>
          < a href="https://www.instagram.com/cwbrews/" target="_blank" rel="noopener noreferrer">
          <SiInstagram />
          </a>
        </div>
       <SubHeading title="Meer Informatie?" />
     <p className="text__menu">Tel: 06-21664081</p>
      <p className="text__menu">Mail: Contact@cwbrews.nl</p>


    </div>
      </div>
     

      <div className="app__specialMenu-menu_img">
        <iframe src="https://my.spline.design/untitled-66edada8ec29934c5d4586264aa3d52a/" frameborder="0" width="100%" height="550px"></iframe>
      </div>

      {/* <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Nieuwste Bieren</p>
        <div className="app__specialMenu_menu_items">
          {data.newbeer.map((newbeer, index) => (
            <MenuItem key={newbeer.title + index} title={newbeer.title} price={newbeer.price} tags={newbeer.tags} />
          ))}
        </div>
      </div> */}
      
    </div>
      {/* <button type="button" className="custom__button button__beer">Bekijk meer...</button> */}
    {/* <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button button__beer">Bekijk meer...</button>
    </div> */}
</div>
  </div>
);

export default SpecialMenu;


// import React from 'react';

// import { images } from '../../constants';
// import './SpecialMenu.css';

// const SpecialMenu = () => (
//   <div className="app__bg app__aboutus flex__center " id="about">
// <div className=" app__about_bg">
//     <div className="app__aboutus-content flex__center">
//       <div className="app__aboutus-content_about">
//         <h1 className="headtext__cormorant">CW Brews</h1>
//         {/* <img src={images.spoon} alt="about_spoon" className="spoon__img" /> */}
//         <p className="text__menu">Naast onze bierbrouwerij, die bekend staat om zijn hoogwaardige bieren, functioneert deze sfeervolle locatie ook als een centrum voor dagbesteding. Hier combineren we de passie voor brouwen met de zorg voor de gemeenschap, door mensen een zinvolle daginvulling te bieden.</p>
//         <a href="https://cwbrews.nl/">
//         <button type="button" className="custom__button">Lees Meer...</button>
//         </a>
//       </div>

//       {/* <div className="app__aboutus-content_history">
//         <h1 className="headtext__cormorant">Over Buro3o</h1>
//         <img src={images.spoon} alt="about_spoon" className="spoon__img" />
//         <p className="text__menu">De missie van Buro3o is het zorgen voor ontwikkeling door  aanbod in behandeling, begeleiding en ondersteuning voor jonge mensen met ontwikkelstoornissen of psychische kwetsbaarheid, waardoor de ontwikkeling weer op gang komt en zij weer de regie over hun eigen leven kunnen krijgen of behouden of groeien in regulatie, zelfredzaamheid en zelfstandigheid.</p>
//         <a href="https://www.buro3o.nl/">
//         <button type="button" className="custom__button">Lees meer...</button>
//         </a>
//         <img src={images.knife} alt="about_knife" />

//       </div> */}
//     </div>
//     </div>
//     <div className="floating-image">
//        <iframe src="https://my.spline.design/untitled-66edada8ec29934c5d4586264aa3d52a/" frameborder="0" width="100%" height="400px"></iframe>
//     {/* <img src={images.knife} alt="about_knife" /> */}
//     </div>
//   </div>
// );

// export default SpecialMenu;