import React from 'react';

import { images } from '../../constants';
import './Chef.css';

const AboutUs = () => (
  <div className="app__bg app__aboutus flex__center " id="about">
     <div className="floating-logo">
    <img src={images.buro3o} alt="about_knife" />
    </div>
<div className=" app__chef_bg">
    <div className="app__chef-content flex__center">
      <div className="app__chef-content_about">
        <h1 className="headtext__cormorant">Over Buro3o</h1>
        {/* <img src={images.spoon} alt="about_spoon" className="spoon__img spoon__logo" /> */}
        <p className="text__menu">Hier op de boerderij van het prachtige landgoed Coudewater werken wij als onderdeel van de compassiefabriek. De compassiefabriek bied psychisch kwetsbare jongeren een betekenisvolle leeromgeving waar zij in een beschermde werkomgeving werkervaring kunnen opbouwen.</p>
        <a href="https://www.buro3o.nl/">
        <button type="button" className="custom__button">Lees meer...</button>
        </a>
      </div>

    </div>
    </div>
  </div>
);

export default AboutUs;