import bg from '../assets/bg.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import buro3o from '../assets/buro3o_logo.svg';

export default {
  bg,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  sign,
  quote,
  buro3o,
};
