import React from 'react';
import { SiTiktok, SiFacebook, SiInstagram } from "react-icons/si";

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="footer">
  
     <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Reserveren of meer informatie?</h1>
        <p className="text___contact">Tel: 06-21664081</p>
        <p className="text___contact">Mail: Contact@cwdeboerderij.nl</p>
        <p className="text___contact">Adres: Peter de Gorterstraat 7A, Rosmalen</p>
      
      </div>

      <div className="app__footer-links_logo">
        <h1 className="app__footer-headtext">Volg ons op onze socials!</h1>
        {/* <p className="footer__quote">&quot;Welkom bij CW de Boerderij - waar goed eten en sociale verantwoordelijkheid hand in hand gaan.&quot;</p> */}
        {/* <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} /> */}
        {/* <div className="app__footer-links_icons">
         
        </div> */}
        <div className="app__icons">
          <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61557419848508" target="_blank" rel="noopener noreferrer">
          <SiFacebook />
          </a>
          <a href="https://www.tiktok.com/@cwdeboerderij" target="_blank" rel="noopener noreferrer">
          <SiTiktok />
          </a>
          < a href="https://www.instagram.com/cwdeboerderij/" target="_blank" rel="noopener noreferrer">
          <SiInstagram />
          </a>
        </div>
      </div>

       <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Openingstijden:</h1>
        {/* <p className="text___contact">Maandag-Dinsdag:</p>
        <p className="text___contact">Gesloten</p>
        <p className="text___contact">Woensdag-Zondag:</p>
        <p className="text___contact">10:00 - 17:30</p> */}
        <div className="time__block">
          <div className="time__block_left">
            <p className="p__opensans_time">Maandag: Gesloten</p>
            <p className="p__opensans_time">Dinsdag: Gesloten</p>
            <p className="p__opensans_time">Woensdag: 10:00 - 17:30</p>
            <p className="p__opensans_time">Donderdag: 10:00 - 17:30</p>
          </div>
        <div className="time__block_right">
            <p className="p__opensans_time">Vrijdag: 10:00 - 17:30</p>
            <p className="p__opensans_time">Zaterdag: 10:00 - 17:30</p>
            <p className="p__opensans_time">Zondag: 10:00 - 17:30</p>
        </div>
        </div>
      </div> 
    </div>
{/* 
    <div className="footer__copyright">
      <p className="text___footer">Dit Is Een Horeca-Concept In Samenwerking Met Buro3o.</p>
    </div> */}

  </div>
);

export default Footer;