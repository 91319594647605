import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar app__bg">
      <div className="app__navbar-logo">
        {/* <img src={images.gericht} alt="app__logo" /> */}
        <h1 className="logo_text">CW De Boerderij</h1>
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="#home">Home</a></li>
        <li className="p__opensans"><a href="#about">Over ons</a></li>
        <li className="p__opensans"><a href="#menu">De Brouwerij</a></li>
        {/* <li className="p__opensans"><a href="#awards">Awards</a></li> */}
        <li className="p__opensans"><a href="#footer">Contact</a></li>
      </ul>
      <div className="app__navbar-login">
        {/* <a href="#login" className="p__opensans">Log In / Registration</a> */}
        <div />
        <a href="#footer" className="p__opensans">Reserveren</a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu className='hamburger__menu' color="white" fontSize={20} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>Over Ons</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>De Brouwerij</a></li>
              {/* <li><a href="#awards" onClick={() => setToggleMenu(false)}>Awards</a></li> */}
              <li><a href="#footer" onClick={() => setToggleMenu(false)}>Contact</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
